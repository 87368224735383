

import React from "react";
import styled from 'styled-components';

export const RetmonRotateIcon = props => (
  <svg viewBox="0 0 22.97 25.2" {...props}>
    <g data-name="Layer 2">
      <path
        d="M20.84 4.74L13.62.57a4.28 4.28 0 00-4.27 0L2.14 4.74A4.27 4.27 0 000 8.44v8.33a4.3 4.3 0 002.14 3.7l7.21 4.16a4.28 4.28 0 004.27 0l7.22-4.16a4.31 4.31 0 002.16-3.7V8.44a4.28 4.28 0 00-2.16-3.7zM.87 16a.81.81 0 01.74-.81V8.68a3.12 3.12 0 01.67-1.91A3.18 3.18 0 013.15 6L10 2.09a3.08 3.08 0 013.08 0L19.82 6a4.27 4.27 0 01.41.29l.09.08a3.14 3.14 0 01.31.32 3 3 0 01.52.93 3.11 3.11 0 01.18 1 3.11 3.11 0 00-.18-1 2 2 0 00-1.84 1.62l-2.19.37a2 2 0 00-1.71-1 2 2 0 00-2 2 2 2 0 00.32 1.07l-1.6 2.11a1.86 1.86 0 00-.58-.1 2 2 0 00-1.13.36L8.93 13a2.26 2.26 0 00.07-.55 2 2 0 00-4 0 2.42 2.42 0 00.06.47l-2.21 1.51a2 2 0 00-1.16-.38h-.08v1.17h.08a.82.82 0 010 1.64h-.06A.82.82 0 01.87 16zm15.39-5.39a.82.82 0 11-.82-.82.82.82 0 01.82.85zm-3.84 5.07a.82.82 0 11-.82-.82.82.82 0 01.82.85zm-4.58-3.23a.82.82 0 11-.84-.82.82.82 0 01.84.82zm13.52 4.07a2.73 2.73 0 010 .41 3.07 3.07 0 01-1.51 2.26L13 23.12l-.37.18a3.09 3.09 0 01-2.35 0l-.28-.18-6.8-3.93a2.71 2.71 0 01-.4-.19l-.28-.25A3.16 3.16 0 012 18a2 2 0 001.67-2 1.85 1.85 0 00-.07-.48L5.85 14a2 2 0 002.3 0l1.55 1.1a1.84 1.84 0 00-.08.53 2 2 0 103.64-1.08l1.59-2.11a1.92 1.92 0 00.59.1 2 2 0 002-1.63l2.18-.38a2 2 0 001.72 1h.07zm0-6.07h-.07a.82.82 0 010-1.64h.07a.81.81 0 010 1.62z"
        fill="#d50c48"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export const Rotate = styled.div`


svg{
  width: 12rem;
  margin: 3rem;
}
`;


