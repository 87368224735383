import React from 'react';
import SEO from '../components/seo';
import LocalizedLink from '../components/LocalizedLink'
import * as S from "../components/Icon/styled"
import DocumentDownload from "../components/LandingPage/documentDownload"
import "../components/CSS/rotate.css"

const NotFound = () => {
  return (
    <>
      <div className="homepage">
      <DocumentDownload/>
        <SEO title="404 | Retmon" />
        <div
          style={{
            height: '100vh',
            textAlign: '-webkit-center',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} >
          <div
            style={{
              marginBottom: '20rem',
            }}
          >
            <S.Rotate className='rotating'>
              <S.RetmonRotateIcon />
            </S.Rotate>
            <h1>404</h1>
            <br></br>
            <LocalizedLink to='/'> Ana Sayfaya Dön</LocalizedLink>
          </div>
        </div>
      </div>
    </>
  )
}
export default NotFound
